.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Estilo das caixas de serviço */
.service-box {
  border: 1px solid #3a3a3a; /* Borda fina e com cor cinza escuro */
  border-radius: 12px; /* Bordas arredondadas */
  width: 220px; /* Definir uma largura fixa para todas as caixas */
  background-color: #2a2a2a; /* Cor de fundo similar ao design */
  transition: transform 0.3s, background-color 0.3s, border-color 0.3s;
}

/* Efeito ao passar o rato sobre as caixas */
.service-box:hover {
  background-color: #333; /* Muda a cor de fundo no hover */
  border-color: #ecd8ba; /* Muda a cor da borda no hover */
  transform: scale(1.05); /* Efeito de zoom */
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
