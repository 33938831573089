@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Latinka';
    src: url('./fonts/Latinka Regular.ttf') format('truetype');
    font-weight: 400; /* Regular */
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Latinka';
    src: url('./fonts/Latinka Italic.ttf') format('truetype');
    font-weight: 400; /* Regular Italic */
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Latinka';
    src: url('./fonts/Latinka SemiBold.ttf') format('truetype');
    font-weight: 600; /* SemiBold */
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Latinka';
    src: url('./fonts/Latinka SemiBold Italic.ttf') format('truetype');
    font-weight: 600; /* SemiBold Italic */
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Latinka';
    src: url('./fonts/Latinka Bold.ttf') format('truetype');
    font-weight: 700; /* Bold */
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Latinka';
    src: url('./fonts/Latinka Bold Italic.ttf') format('truetype');
    font-weight: 700; /* Bold Italic */
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Latinka';
    src: url('./fonts/Latinka Black.ttf') format('truetype');
    font-weight: 900; /* Black */
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Latinka';
    src: url('./fonts/Latinka Black Italic.ttf') format('truetype');
    font-weight: 900; /* Black Italic */
    font-style: italic;
  }

  body, h1, h2, h3, h4, h5, h6 {
    font-family: 'Latinka', sans-serif;
  }
  